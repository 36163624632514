














.wrapper_404 {
  text-align: center;
  margin-top: 10%;

  :nth-child(1) {
    font-size: 45px;
    opacity: .65;
  }

  :nth-child(2) {
    font-size: 16px;
    opacity: .65;
    margin-bottom: 20px
  }
}
